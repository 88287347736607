<template>
    <div>
        <TextContent>
        <h4>Impressum</h4>
        <p>Angaben gem. § 5 TMG</p>
        <p>Weingut Bechtel</p>
        <p>Hinterstraße 10 <br>
        D-55278 Eimsheim <br>
        Tel: <a href="tel:+49 (0) 6249 / 1388">+49 (0) 6249 / 1388</a> <br>
        E-Mail: <a href="mailto:info@bechtel.se">info@bechtel.se</a> <br>
        Internet: <a href="www.bechtel.se">www.bechtel.se</a> </p>

        <p>Geschäftsleitung: Heiko Bechtel </p>
        <p>Steuernummer: DE44 230 71497</p>

        <p>
            Aufsichtsbehörde: <br>
            Ministerium für Wirtschaft, Verkehr, Landwirtschaft und Weinbau
            Stiftsstraße 9 55116 Mainz    Internet: https://mueef.rlp.de 
            Wir gehören der Landwirtschaftskammer Rheinland-Pfalz, Burgenlandstr. 7, 55543 Bad Kreuznach an. 
        </p>
        <p>
            Gesetzliche Grundlage unserer Tätigkeit: Weingesetz
        </p>
        <p>
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            Heiko Bechtel, Skogsbacken 14, S-163 54 Spånga, Schweden
        </p>
        <p>
            Haftungshinweis: <br>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
        <p>
            Plattform der EU-Kommission zur Online-Streitbeilegung:   https://ec.europa.eu/consumers/odr/.							Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
        <p>
            Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
        </p>

    </TextContent>
    </div>
</template>

<script>
    import TextContent from '../components/contents/TextContent.vue'

    export default {
        components: { TextContent },
        setup() {
            
            return { }
        }
    }
</script>

<style scoped>

</style>
