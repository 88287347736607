<template>
  <div>
    <VerifyAge @oldEnough="verifyed" @notOldEnough="redirectAlcHelp" yes="Ja" no="Nej" id="verify-age" class="display-none" >
      {{ ageText }}
    </VerifyAge>
    <div id="root-grid" class="display-grid" >
      <div class="ns" id="nsl"></div> <!---->
      <Navigation id="nav" />
      <div class="ns" id="nsr"></div>
      <div id="vsl"></div> <!-- -->
      <router-view id="view" />
      <div id="vsr"></div> <!---->
      <!-- <Footer id="foot"/> -->
      <Footer id="foot">
        <p>Email: <a href = "mailto: vingarden@bechtel.se">info@bechtel.se</a></p>
        <p>Tel: <a href="tel:+46721749739">+46721749739</a></p>
        <p>Instagram: <a href="http://instagram.com/_u/oliver.bechtel/">oliver.bechtel</a></p>
        <p><router-link to="/de-DE/Impressum">Impressum</router-link></p>
      </Footer>
    </div>
  </div>
</template>

  <script>
  import { ref } from 'vue'
  import VerifyAge from '@/components/VerifyAge.vue'
  import Navigation from '@/components/Navigation.vue'
  import Footer from '@/components/Footer.vue'

  export default {

    setup(props, context) {

      let ageText = ref("Är du 20 eller äldre?")

      const verifyed = () => {
        document.getElementById('verify-age').classList.toggle('display-none')
        document.getElementById('root-grid' ).classList.toggle('display-none')
        document.getElementById('root-grid' ).classList.toggle('display-grid')
      }

      const unverifyed = () => {
        document.getElementById('verify-age').classList.toggle('display-none')
        document.getElementById('root-grid' ).classList.toggle('display-grid')
        document.getElementById('root-grid' ).classList.toggle('display-none')
      }

      const toogleVerifyed = () => {

      }

      const redirectAlcHelp = () => {

        switch(window.location.pathname) {

          case "/de-DE":
            window.location.assign("https://www.kenn-dein-limit.info/")
            break

          default:
            window.location.assign('https://www.iq.se/tonarsparloren/varfor-ar-alkohol-farligt-for-unga/')
        }
      }

      return { verifyed, unverifyed, redirectAlcHelp, ageText }
    },
    mounted() {

      try {
        let age = document.cookie
          .split(',')
          .find(row => row.startsWith('age='))
          .split('=')[1]
        
        if(!(age === "18")) this.unverifyed()
      } catch (error) {
        this.unverifyed()
      }


      if (window.location.pathname === '/') {

        
        let lang = navigator.language || navigator.userLanguage
        
        switch(lang) {

          case "de-DE":
            this.$router.push("/de-DE");
            break

          case "sv-SE":
          case "en-GB":
          default:
            this.$router.push("/")
            break
        }
      }

      
      switch(window.location.pathname) {

        case "/de-DE":
          this.ageText = "Bist du über 18?"
          break
      }

          
    },

    components: { VerifyAge, Navigation, Footer }
  }

</script>

<style>

  .display-none {
    display: none;
  }

  .display-grid {
    display: grid;
  }

  #verify-age {
    height: 100vh;
    /* height: 100%; */
    width: 100vw;
    overflow: hidden;
    /* display: none; */

    background-color: #333;
  }

  #root-grid {
    /* display: grid; */
    /* display: none; */
    min-height: 100vh;
    /*grid-template-columns: 1fr;
    grid-template-rows: 4em 1fr auto;*/
    /*grid: 4em 1fr auto / 0px 1fr 0px;*/
    grid: 4em 1fr auto / 0px 1fr 0px;

    /*grid-template-areas: 
      "navs nav navs"
      "views view views"
      "foot foot foot";
  }*/

  grid-template-areas: 
      "nsl nav nsr"
      "vsl view vsr"
      "foot foot foot";
  }

  #nsl { grid-area: nsl; }
  #nsr { grid-area: nsr; }
  #vsl { grid-area: vsl; }/**/
  #vsr { grid-area: vsr; }/**/

  #nav  { grid-area: nav;  }
  #view { grid-area: view; }
  #foot { grid-area: foot; }

  html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    max-width: 100%;
    overflow-x: hidden;/*/**/
  }

  @media screen and (min-width: 1200px) {

    #root-grid {
      /*grid: 4em 1fr auto / 1fr 1000px 1fr; /** */
      grid-template-rows: 4em 1fr auto;
      grid-template-columns: 1fr 1200px 1fr;
    }

    .ns {
      background-color: #333;
      height: 100%;
      width: 100%;
  }
  }
</style>
