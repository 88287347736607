<template>
    <div class="title">
        <p>
            <slot></slot>
        </p>
    </div>
</template>

<script>
    export default {
        props: {
                font: { type: String, default: "32px"}
        },
        setup(props, context) {

            const  { font } = props
            return { font }
        }
    }
</script>

<style scoped>
    .title {
        height: 100%;
        width: 100%;

        text-indent: 0px;

        /* font-size: 32px; */
        font-size: v-bind(font);
        text-align: start;
        /* margin: 0px 1em;; */
    }

    @media screen and (max-width: 650px) {

        .title {
            text-align: center;
            text-indent: 0;
            margin: 1vw 0;
            
        }
    }
    
</style>