import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GermanView from '../views/GermanView.vue'
import GermanImprintView from '../views/GermanImprintView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/de-DE',
    name: 'german',
    component: GermanView
  },
  {
    path: '/de-DE/Impressum',
    name: 'impressum',
    component: GermanImprintView
  }/* ,
  {
    path: '/404',
    alias: '*',
    component: NotFound
  } */
  /*,
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */// '../views/AboutView.vue')
  //}/**/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
