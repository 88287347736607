<template>
    <div class="content5">
        <div class="title">
            <TitleContent>
                Från vinstock till vin
            </TitleContent>
        </div>

        <div class="img1">
            <ImgContent>
                <img src="@/assets/klippa-vin-oli.jpg" alt="Oliver Bechtel klipper vin">
            </ImgContent>
        </div>

        <div class="text text1">
            <TextContent>
                <p>Året på vingården börjar med att varje vinstock beskärs. De gamla grenarna ovanför stammen klipps bort och endast en gren sparas från vilken de nya skotten kommer att växa ut. </p>
            </TextContent>
        </div>
        <div class="img2">
            <ImgContent>
                <img src="@/assets/vinberg-vinter-vy.jpg" alt="Vinodling på vintern">
            </ImgContent>
        </div>
        <div class="text text2">
            <TextContent>
                <p>Bild på vinodlingen på vintern. </p> 
            </TextContent>
        </div>
        <div class="img3">
            <ImgContent>
                <img src="@/assets/vinodlingjuni-ny.jpeg" alt="Vinodling Juni">
            </ImgContent>
        </div>
        <div class="text text3">
            <TextContent>
                <p>I maj och juni växer vinrankorna och behöver vid flera tillfällen rätas upp med hjälp av spaljétrådar.</p>
            </TextContent>
        </div>
        <div class="img4">
            <ImgContent>
                <img src="@/assets/druvor-på-vinstock-vy.jpg" alt="vindruvor i rieslingfältet, druvor på vinstock + vy">
            </ImgContent>
        </div>
        <div class="text text4">
            <TextContent>
                <p>I augusti börjar druvorna att mogna. På denna bild ses druvorna Riesling som senare kommer bli vinet <i>Sommaräng</i>.</p>
            </TextContent>
        </div>
        <div class="img5">
            <ImgContent>
                <img src="@/assets/druvor-på-vinstocken.jpg" alt="druvor på vinstocken närbild">
            </ImgContent>
        </div>
        <div class="text text5">
            <TextContent>
                <p>Druvorna växer i täta klasar vilket är typiskt för Riesling. Riesling är den vanligaste vinsorten i vindistriktet Rheinhessen där Vingården Bechtel ligger.</p>
            </TextContent>
        </div>
        <div class="img6">
            <ImgContent>
                <img src="@/assets/skördade-druvor.jpg" alt="druvor i hand">
            </ImgContent>
        </div>
        <div class="text text6">
            <TextContent>
                <p>När druvorna ser ut så här börjar det bli dags för skörd. Innan dessa druvor skördades klipptes vissa druvor bort för hand. Det var de druvor som ännu inte var mogna eller redan övermogna. Detta för att höja kvaliteten på det blivande vinet.</p>
            </TextContent>
        </div>
        <div class="img7">
            <ImgContent>
                <img src="@/assets/druv-skorde-maskin.jpg" alt="Vollernter">
            </ImgContent>
        </div>
        <div class="text text7">
            <TextContent>
                <p>Den 14 oktober 2021 skördades druvorna <!-- Riesling --> med hjälp av en druvskördemaskin.</p>
            </TextContent>
        </div>
        <div class="img8">
            <ImgContent>
                <img src="@/assets/druvskördemaskin-vy.jpg" alt="Vy från vinskördemaskin">
            </ImgContent>
        </div>
        <div class="text text8">
            <TextContent>
                <p>Druvskördemaskinen skakar loss druvorna från vinstocken och fångar upp dem. Sedan transporteras de till en behållare på maskinen.</p>
            </TextContent>
        </div>
        <div class="img9">
            <ImgContent>
                <img src="@/assets/druvskördemaskin-tömmer.jpg" alt="tömning av druvorna">
            </ImgContent>
        </div>
        <div class="text text9">
            <TextContent>
                <p>När behållaren är full tömmer vinskördemaskinen ut dem på ett stort släp. En traktor transporterar släpet med vindruvorna hem till gården.</p>
            </TextContent>
        </div>
        <div class="imgA">
            <ImgContent>
                <img src="@/assets/vintankar.jpg" alt="vintankar">
            </ImgContent>
        </div>
        <div class="text textA">
            <TextContent>
                <p>På gården pressas vindruvorna och saften fylls i stora vintankar. Där ligger druvsaften och jäser under en längre tid. När saften har jäst klart är vinet färdigt och fylls på flaskor.</p>
            </TextContent>
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import ImgContent   from '@/components/contents/ImgContent.vue'
    import ProductCard  from '@/components/contents/ProductCard.vue'

    export default {
        components: { TitleContent, TextContent, ImgContent, ProductCard }
    }
</script>

<style scoped>

        .text {
            text-align: center;
            margin-left: 5vw;
            margin-right: 5vw;
            margin-top: 1vh;
            margin-bottom: 3vh;
        }

    .content5 {
            
        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 75px auto auto auto auto auto; 
        gap: 0px 2vw; 
        grid-template-areas: 
                "title title"
                "img1  img2"
                "text1 text2"
                "img3  img4"
                "text3 text4"
                "img5  img6"
                "text5 text6"
                "img7  img8"
                "text7 text8"
                "img9  imgA"
                "text9 textA";
    }

    .title { grid-area: title; }
    .text1 { grid-area: text1; }
    .text2 { grid-area: text2; }
    .text3 { grid-area: text3; }
    .text4 { grid-area: text4; }
    .text5 { grid-area: text5; }
    .text6 { grid-area: text6; }
    .text7 { grid-area: text7; }
    .text8 { grid-area: text8; }
    .text9 { grid-area: text9; }
    .textA { grid-area: textA; }
    .img1  { grid-area: img1;  }
    .img2  { grid-area: img2;  }
    .img3  { grid-area: img3;  }
    .img4  { grid-area: img4;  }
    .img5  { grid-area: img5;  }
    .img6  { grid-area: img6;  }
    .img7  { grid-area: img7;  }
    .img8  { grid-area: img8;  }
    .img9  { grid-area: img9;  }
    .imgA  { grid-area: imgA;  }



    @media screen and (max-width: 750px) {

        /* .text {
            text-align: center;
            margin-left: 5vw;
            margin-right: 5vw;
            margin-top: 1vh;
            margin-bottom: 3vh;
        } */

        .content5 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px auto auto auto auto auto auto auto auto auto auto; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "img1 "
                "text1"
                "img2 "
                "text2"
                "img3 "
                "text3"
                "img4 "
                "text4"
                "img5 " 
                "text5"
                "img6 "
                "text6"
                "img7 "
                "text7"
                "img8 "
                "text8"
                "img9 "
                "text9"
                "imgA "
                "textA";
    
            
        }
    }
</style>