<template>
    <div class="content7">
        <div class="title">
            <TitleContent font="28px">
                Så här gör man för att köpa vin direkt från en producent inom EU:
            </TitleContent>
        </div>
        <div class="text">
            <TextContent font="20px">
                <p>1. Du själv måste ordna med transporten (anlita ett transportbolag eller be än vän/bekant att ta med varorna åt dig).</p>
                <p>2. Införseln måste anmälas i förväg till Skatteverket via deras eTjänst eller via en särskild blankett.</p>
                <p>3. En säkerhet ska ställas hos Skatteverket på ett belopp som motsvarar alkoholskatten (19,63 kr / flaska). Med andra ord, skatten ska betalas in till Skatteverket i förväg.</p>
                <p>4. Då anmälan och säkerhet är Skatteverket tillhanda så tilldelar de dig ett Diarienummer (DNR-nummer). Du får normalt detta via ett brev tillsammans med en deklarationsblankett för alkoholskatten.</p>
                <p>5. DNR-nummer ska du meddela oss så att vi kan upprätta ett s.k. ”ledsagardokument” som ska följa med transporten.</p>
                <p>6. När du har fått varorna så sänder du in deklarationsblanketten till Skatteverket och skatten dras då från det belopp vilket inbetalats tidigare som säkerhet.</p>
                <p>7. Dessa transaktioner kan du sedan följa upp via den e-tjänst som finns på skatteverkets hemsida. Klart!</p>
            </TextContent>
        </div>
    </div>
</template>

<script>

    import TextContent from '@/components/contents/TextContent.vue'
    import TitleContent from '@/components/contents/TitleContent.vue'
    /* import TitleContent from '@/components/contents/TitleContent.vue' */

    export default {
        setup() {

            return { }
        },
        components: { TextContent, TitleContent }
    }
</script>

<style scoped>
    .content7 {
        /* margin: 0 2vw; */
        margin: 0 10em;
        margin-top: 10px;

        text-align: center;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 75px auto;
        gap: 0px 2vw;
        grid-template-areas: 
            "title"
            "text";
    }
    .title { grid-area: title; justify-content: center; text-align: center; }
    .text  { grid-area: text ; font-size: 16px; }

    @media screen and (max-width: 650px) {
        .content7 { margin: 0; margin-top: 10px;}
    }
</style>