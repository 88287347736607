<template>
    <div id="signup-form">
        <form @submit.prevent="signup" id="ffffs">

            <!-- <div id="label">
                <label for="">{{ label }}</label>
            </div> -->

            <div id="input">
                <input type="email" v-model="email" placeholder="Email Address" required>
            </div>

            <!-- <div v-if="emailError">{{ emailError }}</div> -->
            <div id="btn">
                <button>Sign Up</button>
            </div>

        </form>
    </div>
</template>

<script>
    /* const validator = require('email-validator') */
    import { ref } from 'vue'

    export default {
        name: "Signup",
        setup() {

            const label = ref("Email Signup")
            const email = ref("")
            /* const emailError = ref("") */

            const signup = () => {

                //emailError.value = !validator.validate(email.value) ? "" : "Invalid email address"
                /* if(!validator.validate(email.value)) emailError.value = "Invalid Email Address"
                else emailError.value = "" */

                /* if(emailError.value) return; */

                let xhr = new XMLHttpRequest()
                xhr.open("POST", "/api/signup?email=" + email.value, true)
                xhr.send()

                email.value = ""
            }

            return { label, email, /* emailError, */ signup }
        },
        components: {}
    }
</script>

<style scoped>
    #signup-form {
        /*height: 75px;
        width: 350px;/**/
        max-height: 125px;
        width: 100%;
        /*height: 5em;
        width: 23em;*/


        /* background-color: white; */

        padding: 0;

        display: flex;
        justify-content: center;

        /*border: 2px solid black /** 
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);*/

        margin: 0;
        /*margin: 0px 2em;
        margin-left: 2em;*/
        margin:  1em 0px;
    }

    #ffffs:hover {
        box-shadow: 0px 4px 10px +1px rgba(0, 0, 0, .5), 0px 2px 5px +1px rgba(0, 0, 0, .5), 0px 0px 5px 1px rgba(0, 0, 0, .5);
        transition: 250ms;
    }

    #ffffs {
        position: relative;
        height: 100%;
        width: 95%;
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, .35), 0px 2px 4px -1px rgba(0, 0, 0, .25), 0px 0px 2px 0px rgba(0, 0, 0, .25);


        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr;
        grid-gap: 0;
        grid-template-areas: 
          /* "label label" */
          "input btn"; 
    }

    #label { 
        grid-area: label; 
        /*background-color: burlywood; */
        background-color: white;
        /*display: flex;*/
        justify-content: center;
        text-align: center;
        /*align-items: center;*/
        margin: 0;
        padding: .0em;/**/
        padding-bottom: .5em;

        padding: .5em;
        border: 2px solid black;
        border-bottom: 0;
        font-size: 24px;
    }

    #input { grid-area: input; }
    #input > input {
        margin: 0;
        padding: 0;
        height: calc(95% - 2px);
        width: 98%;
        width: 100%;
        /*justify-content: center;/** */
        border: 2px solid black;
        font-size: 18px;
        text-indent: 16px;
    }


    #btn { grid-area: btn;   /*background-color: chocolate;*/ }
    #btn > button {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;

        border: 2px solid black;
        /*border-left: none; */
        background-color: #aaa;
        /* color: white; */
        padding: 10px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
    }

    #btn > button:hover {
        background-color: #888;
        transition: 150ms;
    }


    @media screen and (max-width: 400px) {

        #signup-form { 
            max-height: 250px;
            margin: 0;
            box-shadow: 1px solid black;
        }

        #input > input {
            width: calc(100% - 4px);
        }

        #input {
            border: 1px solid black;
            border-bottom: 0px solid black;
        }

        #btn {
            border: 1px solid black;
        }

        #ffffs {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 0;
            grid-template-areas: 
            /* "label label" */
            "input"
            "btn"; 
        }
    }

</style>