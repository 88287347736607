<template>
    <div class="product-layout">
            <div class="product-container default-shadow default-radius" v-if="product">
                <!-- <img class="product-img" :src=product.img alt=""> -->
                <img class="product-img" src="@/assets/sommarang.jpg" alt="">
                <div class="product-name">
                    {{ product.name }}
                </div>
                <div class="product-price">
                    EUR {{ (product.price/1000).toLocaleString(undefined, { minimumFractionDigits: 2}) }} inkl. MwSt <!-- + Versand -->
                </div>
                <div class="product-quantity">
                    <!-- {{ product.qty }} st -->
                </div>
            </div>
            <div v-else>
                <div class="loading-product-img"></div>
                <div class="product-name">
                    Loading...
                </div>
                <div class="product-price">
                    
                </div>
                <div class="product-quantity">
                    
                </div>
            </div>
        </div>
</template>

<script>
    import { ref } from 'vue'

    export default {
        props: {
            id: { type: String, default: "9ce95e1f-8e02-44fd-adee-f6369e08054f" }
        },
        setup(props, context) {
            var product = ref(null)
            const { id } = props

            return { product, id }
        },
        mounted() {
            fetch('/api/products/' + this.id + '/?lang=sv')
                .then(res  => res.json())
                .then(data => this.product = data)
                .catch(err => console.log(err.message))
        }
    }
</script>

<style>
    .product-layout {
        height: 100%;
        width: 300px; /* 100% */ 
        max-height: 350px;
        /* padding: 0px 2vw; */
        margin: 0 auto;
        border-radius: .25rem;
        /*margin: 0px 2em; /* */
        /*overflow-x: none; /* */
    }

    .product-container {
        height: 100%;
        width: 100%;
        background-color: whitesmoke;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 250px 40px 35px;
        gap: 0px 0px;
        grid-template-areas: 
            "product-img product-img"
            "product-name product-name"
            "product-price product-price";
    }

    /* .product-container:hover {
        box-shadow: 0px 4px 10px +1px rgba(0, 0, 0, .5), 0px 2px 5px +1px rgba(0, 0, 0, .5), 0px 0px 5px 1px rgba(0, 0, 0, .5);
        transition: 250ms;
    } */

    .product-img      { grid-area: product-img;     }
    .product-name     { grid-area: product-name;    }
    .product-price    { grid-area: product-price;   }
    .product-quantity { grid-area: product-quantity; }

    .product-img {
        height: 100%;
        width: 300px /* 100% */;
        overflow: hidden;
        text-align: center;

        object-fit: contain;
        object-fit: cover;
        object-position: center center;
        /*max-width: 250px;*/
        /*max-height: 250px;/** */

        justify-content: center;
        align-self: center;
        text-align: center;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;


    }

    .product-name {
        text-align: center;
        font-size: 24px;
        /*vertical-align: baseline; /* */
        margin-top: .25em;
    }

    .product-price {
        text-align: left;
        
        font-size: 18px;
        text-align: center;
        
        margin-top: .15em;
    }

    .product-quantity {
        text-align: right;
        
        font-size: 18px;
        text-align: center;
        
        margin-top: .15em;
    }

    .loading-product-img {
        height: 250px;
        width: 100%;
        background-color: #aaa;
    }

</style>