<template>
    <div id="content-root-grid">
        <div class="content4">
            <content4 /> 
        </div>
        <div class="content1">
            <content1 /> 
        </div>
        <div class="content2">
            <content2 /> 
        </div>
        <div class="content3">
            <div style="height: 20px;"></div>
            <content3 />
        </div>
        <div class="content6">
            <content6 /> 
        </div>
        <div class="content5">
            <div style="height: 10px;"></div>
            <content5 /> 
        </div>
        <div class="content7">
            <content7 />
        </div>
    </div> 
</template>

<script>
    import content1 from '@/components/contents/content1.vue'
    import content2 from '@/components/contents/content2.vue'
    import content3 from '@/components/contents/content3.vue'
    import content4 from '@/components/contents/content4.vue'
    import content5 from '@/components/contents/content5.vue'
    import content6 from '@/components/contents/content6.vue'
    import content7 from '@/components/contents/content7.vue'

    export default {
        name: "Content",
        setup() {
            return { }
        },
        components: { content1, content2, content3, content4, content5, content6, content7 }
    }
</script>

<style scoped>
    #content-root-grid {

        background-color: whitesmoke;
        
        /*background-color: #005691;
        padding-left: 20%;
        padding-right: 20%;/**/
        padding-top: .5em;
        padding-bottom: 2em;
        font-size: 18px;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto;
        gap: 5px 0px;
        grid-template-areas: 
            "content4"
            "content1"
            "content2"
            "content3"
            "content6"
            "content5"
            "content7";
        
        /*display: grid; 
        /*grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 75px 1fr 75px 1fr 75px 1fr; /*
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
        grid-template-rows: 75px auto 75px auto 75px auto; 
        gap: 5px 5px; 
        /*grid-template-areas: 
            "Titel1 Titel1 Titel1"
            "Text1 Text1 Bild1"
            "Title2 Title2 Title2"
            "Bild2 Text2 Text2"
            "Title3 Title3 Title3"
            "Text3 Text3 Bild3"; /*
        grid-template-areas: 
            "Title1 Title1 Title1 Title1 Title1 Title1"
            "Text1 Text1 Text1 Signup Signup Signup"
            "Title2 Title2 Title2 Title2 Title2 Title2"
            "Bild2 Bild2 Text2 Text2 Text2 Text2"
            "Title3 Title3 Title3 Title3 Title3 Title3"
            "Text3 Text3 Text3 Text3 Bild3 Bild3"; /* */
    }

    .content1 { grid-area: content1; }
    .content2 { grid-area: content2; }
    .content3 { grid-area: content3; }
    .content4 { grid-area: content4; }
    .content5 { grid-area: content5;}
    .content6 { grid-area: content6; }
    .content7 { grid-area: content7; }

    /*.Title1 { grid-area: Title1; }
    .Text1  { grid-area: Text1;  }
    .Signup { grid-area: Signup; }
    .Title2 { grid-area: Title2; }
    .Bild2  { grid-area: Bild2;  }
    .Text2  { grid-area: Text2;  }
    .Title3 { grid-area: Title3; }
    .Text3  { grid-area: Text3;  }
    .Bild3  { grid-area: Bild3;  }  /* */


</style>