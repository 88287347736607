<template>
    <div class="content1">
        <div class="title">
            <TitleContent>
                Newsletter
            </TitleContent>
        </div>
        <div class="text">
            <TextContent>
                <p>Var den första att veta om våra senaste nyheter. </p>
            </TextContent>
        </div>
        <div class="signup">
            <Signup />
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import Signup       from '@/components/contents/Signup.vue'

    export default {
        components: { TitleContent, TextContent, Signup }
    }
</script>

<style scoped>
    .content1 {
        margin: 0 2vw;
            
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 75px 1fr; 
        gap: 0px 1vw; 
        grid-template-areas: 
            "title title"
            "text signup"; 
    }

    .title { grid-area: title; }
    .text { grid-area: text; }
    .signup { grid-area: signup; }

    @media screen and (max-width: 960px) {
        .content1 {
            grid-template-columns: 1fr; 
            grid-template-rows: 75px 125px 1fr; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "signup"
                "text"; 
            
        }
    }
</style>