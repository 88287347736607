<template>
    <div class="img  default-radius">
        <!-- <img src="@/assets/unnamed.jpg" alt=""> -->
        <slot></slot>
    </div>
</template>

<script>
export default {

}
</script>

<style>

    .img {
        justify-content: center;
        align-content: center;
        display: flex;

        
    }
    .img > img {
        height: calc(1024px / 2.5);
        width: calc(768px / 2.5);
        border-radius: 0px;
        border-radius: .25rem;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, .35), 0px 2px 4px -1px rgba(0, 0, 0, .25), 0px 0px 2px 0px rgba(0, 0, 0, .25);
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, .35), 0px 2px 4px -1px rgba(0, 0, 0, .25), 0px 0px 2px 0px rgba(0, 0, 0, .25);
        transition: 250ms;
    }
    .img > img:hover {
        box-shadow: 0px 4px 10px +1px rgba(0, 0, 0, .5), 0px 2px 5px +1px rgba(0, 0, 0, .5), 0px 0px 5px 1px rgba(0, 0, 0, .5);
        transition: 250ms;
    }
</style>