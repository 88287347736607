<template>
    <div class="content">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
                font: { type: String, default: "24px"}
        },
        setup(props, context) {

            const  { font } = props
            return { font }
        }
    }
</script>

<style scoped>
    .content {
        /* font-size: 24px; */
        font-size: v-bind(font);
        justify-content: center;
        align-content: center;
        margin: 0px 0/* 1vw */;
        
        /* text-align: center;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 1vh;
        margin-bottom: 3vh;
     */
    }

    @media screen and (max-width: 650px) {

        .content { 
            /* text-align: center; */

            margin: 0 2vw; 
        }
    }
</style>