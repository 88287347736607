<!-- https://ionic.io/ionicons -->
<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        class="ionicon" 
        viewBox="0 0 512 512"
    >
        <title>Menu</title>
        <path 
            fill="none" 
            stroke="currentColor" 
            stroke-linecap="round" 
            stroke-miterlimit="10" 
            stroke-width="32" 
            d="M80 160h352M80 256h352M80 352h352"
        />
    </svg>
</template>