<template>
    <div class="content3">
        <div class="title">
            <TitleContent>
                Über mich
            </TitleContent>
        </div>
        <div class="text">
            <TextContent>
                <p>Ich bin Oliver Bechtel, geboren 2002 in Brüssel, Mutter Schwedin, Vater Deutscher, aufgewachsen in Stockholm, jetzt in Deutschland um Winzer zu werden. Seit vielen Generationen wird in der Familie Wein angebaut doch nachdem mein Vater eine andere berufliche Laufbahn eingeschlagen hatte war die Zukunft des Weingutes ungewiss. Meine Großmutter und mein Großvater arbeiteten weiter, begrenzten sich in den letzten Jahren jedoch auf den Aussenbetrieb und den Weinanbau - und hofften insgeheim dass eventuell ein Enkel das Weingut weiterführen würde.</p>
                <p>Jetzt bin ich vor Ort, habe 2021 meine Winzerlehre begonnen und gemeinsam kümmern wir uns um das Weingut und die ca. 60.000 Rebstöcke der unterschiedlichen Rebsorten. Langsam aber sicher beginnen wir wieder mit dem Aufbau des Weinsortimentes und werden  nach und nach verschiedene Weine anbieten können.</p>
            </TextContent>
        </div>
        <div class="img">
            <ImgContent>
                <img src="@/assets/oliver.jpg" alt="">
            </ImgContent>
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import ImgContent   from '@/components/contents/ImgContent.vue'

    export default {
        components: { TitleContent, TextContent, ImgContent }
    }
</script>

<style scoped>
    .content3 {

        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 2fr 1fr; 
        grid-template-rows: 75px auto auto; 
        gap: 0px 2vw; 
        grid-template-areas: 
            "title title"
            "text img"
            "text2 text2"; 
    }

    .title { grid-area: title; }
    .text { grid-area: text; }
    .img { grid-area: img; }


    @media screen and (max-width: 900px) {

        .content3 {
            display: grid; 
            grid-template-columns: 1fr 300px; 
            grid-template-rows: 75px auto auto; 
            /* gap: 0px 0px; */ 
            grid-template-areas: 
                "title title"
                "text img"
                "text2 text2"; 
            
        }
    }

    @media screen and (max-width: 650px) {

        .content3 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px auto auto auto; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "img"
                "text"
                "text2"; 
            
        }
    }

</style>