<template>
  <div class="home">
    <Header class="H1" />
    <Content class="C1" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Content from '@/components/Content.vue'

export default {
  name: 'HomeView',
  components: {Header, Content}
}
</script>

<style scoped>
  .home {
    height: 100%;
    border: 1px solid black;
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 600px 1fr; 
    gap: 5px 0px; 
    grid-template-areas: 
      "H1"
      "C1";  /*/*/
    }

  .H1 { grid-area: H1; }
  .C1 { grid-area: C1; } /*/*/

</style>