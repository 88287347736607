<template>
    <div class="content2">
        <div class="title">
            <TitleContent>
                Mer om <i>Sommaräng</i>
            </TitleContent>
        </div>
        <div class="text">
            <TextContent>
                <p>Vinet <i>Sommaräng</i> är tillverkat av den klassiska druvsorten Riesling. <i>Sommaräng</i> har en lätt och fruktig smak med en fin nyans av citrus. Den är också något syrlig vilket är typiskt för en Riesling. <i>Sommaräng</i> passar utmärkt som dryck till fisk och ljust kött. Det går också utmärkt att njuta av <i>Sommaräng</i> bara som den är, utan tilltugg.</p>
                <p>Serveras väl kylt, ca 8 - 10 grader.</p>
                <p>Cirkapriset för en flaska <i>Sommaräng</i> är 125 kr <br><i><div style="font-size: 18px">Pris inkl. moms 85 kr + alkoholskatt 19,63 kr + transportkostnad ca. 20 kr </div></i></p>
            </TextContent>
        </div>
        <div class="product">
            <ProductCard id="9ce95e1f-8e02-44fd-adee-f6369e08054f" />
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import ImgContent   from '@/components/contents/ImgContent.vue'
    import ProductCard  from '@/components/contents/ProductCard.vue'

    export default {
        components: { TitleContent, TextContent, ImgContent, ProductCard }
    }
</script>

<style scoped>
    .content2 {
            
        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 300px 1fr; 
        grid-template-rows: 75px 1fr; 
        gap: 0px 2vw; 
        grid-template-areas: 
            "title title"
            "product text"; 
    }

    .title { grid-area: title; }
    .text { grid-area: text; }
    .product { grid-area: product; }


    /* @media screen and (max-width: 900px) {

        .content2 {
            display: grid; 
            grid-template-columns: 300px 1fr; 
            grid-template-rows: 75px 1fr; 
            /* gap: 0px 0px;  * /
            grid-template-areas: 
                "title title"
                "product text"; 
            
        }
    } */

    @media screen and (max-width: 800px) {

        .content2 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px 1fr auto; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "product"
                "text"; 
            
        }
    }
</style>