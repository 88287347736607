<template>
    <div class="header">
        <div id="icon1"><iconTcross /></div>
        <div id="icon2"><iconAlge /></div>
        <div id="icon3"></div>
        <!-- <img id="img" src="@/assets/flor-saurina-Rk434ULROpM-unsplash.jpg"> -->
        <div id="img2"></div>
    </div>
</template>

<script>
    import iconAlge from '../assets/Alge.vue'
    import iconTcross from '../assets/Tcross.vue'

    export default {
        name: "Header",
        components: { iconAlge, iconTcross }
    }

</script>

<style>
    #img2 {
        height: 600px;

        /* background: url('@/assets/flor-saurina-Rk434ULROpM-unsplash.jpg'); */
        background: url('@/assets/header.jpg');
        background-position: center;
        background-size: cover;
    }

    template {
        width: 100%;
    }

    #img {
        height: 600px;
        width: 100%;
        align-self: center;
    }

    .header {

        width: 100%;
        height: 600px;
        /*border: 3px dashed red;/** */
        border-bottom: 1px solid black;

        position: relative;
    }

    #icon1 {
        display: none;
    }

    #icon2 {
        display: none;
    }    

</style>


<!-- 



    /**
     * 
     * 

        position: relative;
        top: 0;
        left: 0;
        height: 500px;

        position: relative;
        top: 0;
        left: 0;
        border: 2px dotted red;

        position: absolute;
        top: -30px;
        left: 230px;
        border: 2px dotted red;
     */
</script>

<style>
    template {
        width: 100%;
    }

    #img {
        max-height: 500px;
        width: 100%;
        align-self: center;
    }

    .header {

        width: 100%;
        height: 500px;
        /*border: 3px dashed red;/** */
        border-bottom: 2px solid black;

        position: relative;

        
        /* FLEX
        /*width: 100%;
        height: 500px;
        border: 3px dashed red;

        display: flex;
        justify-content: center;
        align-items: center;*/


        /*
        width: 100%;
        height: 500px;
        border: 2px dotted red;

        position: relative;*/

        /*width: 100%;
        height: 500px;
        margin: 0;
        background-color: #333;

        display: flex;
        flex-direction: row;
        justify-content: space-around;*/
    }

    #icon1 {
        display: none;


        /*width: 1000px;
        height: 1000px;
        border: 2px dotted red;
        padding-top: 450px;
        margin-left: -175px;

        position: absolute;
        top: 50%;
        left: 10%;
        transform: translate(-10%, -50%);*/



        /* /* FLEX
        width: 100px;
        height: 100px;
        background-color: limegreen;
        */

        /*width: 100px;
        height: 100px;
        background-color: limegreen;
        border: dotted limegreen;

        position: absolute;
        top: 50%;
        left: 50%;

        top: calc(50% - calc( 100px / 2)) ;
        left: calc(50% - calc( 100px / 2));*/
        
        /*background-color: #aaf;
        position: fixed;
        width: 100px;
        height: 500px;
        margin-left: 0px;
        border: 2px dotted red;

        flex: 1;*/
    }

    #icon2 {
        display: none;


        /*width: 1500px;
        height: 1500px;
        border: 2px dotted red;
        padding-top: 450px;
        margin-left: -175px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-10%, -50%);*/



        /*display: none;*/


        /*background-color: #999;
        position: fixed;
        /*width: 100px;
        height: 500px;
        margin-left: -1000px;*/
        /*border: 2px dotted red;

        /*flex: 2;*/
    }





-->