<template>
  <div class="german">
    <Header class="H" />
    <ContentGerman class="C" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
/* import Content from '@/components/Content.vue' */
import ContentGerman from '@/components/ContentGerman.vue'

export default {
  name: 'HomeView',
  components: {Header, ContentGerman}
}
</script>

<style scoped>
  .german {
    height: 100%;
    border: 1px solid black;
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 600px 1fr; 
    gap: 5px 0px; 
    grid-template-areas: 
      "H"
      "C";  /*/*/
    }

  .H { grid-area: H; }
  .C { grid-area: C; } /*/*/

</style>