<template>
    <div class="content3">
        <div class="title">
            <TitleContent>
                Så här köper du vinet
            </TitleContent>
        </div>
        <div class="text">
            <TextContent><!-- 
                <p>Cirkapriset för en flaska <i>Sommaräng</i> är ca. 125 kr </p>
                <p>Pris inkl. moms 85 kr + alkoholskatt 19,63 kr + transportkostnad ca. 20 kr</p> -->
                <!-- <p> + 85 kr  priset inkl. moms</p>
                <p> + 19,63 kr alkoholskatt</p>
                <p> + ca. 20 kr transportkostnad</p>
                <p></p> -->
                <p>För närvarande kan man bara köpa vinet <i>Sommaräng</i> direkt från vingården i Tyskland. Enklaste sättet är att maila mig din beställning: <a href="mailto: info@bechtel.se">info@bechtel.se</a>. Längst ner på sidan beskrivs processen steg för steg. Kontakta mig så förklarar jag mer detaljerat hur processen går till och hur jag kan hjälpa till. Det är lite krångligt men inte alls omöjligt och vinet är väl värt besväret!</p>
                <p>Jag letar <!-- fortfarande --> även efter en svensk vinimportör för att kunna möjliggöra försäljning via Systembolaget och hoppas att så småningom kunna erbjuda detta enklare sätt i framtiden.</p>
            </TextContent>
        </div>
        <div class="img">
            <ImgContent>
                <img src="@/assets/unnamed.jpg" alt="">
            </ImgContent>
        </div>
        <!-- <div class="text2">
            <TextContent>
                <h3>Så här gör man för att köpa vin direkt från en producent inom EU:</h3>
                <p>1. Du själv måste ordna med transporten (anlita ett transportbolag eller be än vän/bekant att ta med varorna åt dig).</p>
                <p>2. Införseln måste anmälas i förväg till Skatteverket via deras eTjänst eller via en särskild blankett.</p>
                <p>3. En säkerhet ska ställas hos Skatteverket på ett belopp som motsvarar alkoholskatten (19,63 kr / flaska). Med andra ord, skatten ska betalas in till Skatteverket i förväg.</p>
                <p>4. Då anmälan och säkerhet är Skatteverket tillhanda så tilldelar de dig ett Diarienummer (DNR-nummer). Du får normalt detta via ett brev tillsammans med en deklarationsblankett för alkoholskatten.</p>
                <p>5. DNR-nummer ska du meddela oss så att vi kan upprätta ett s.k. ”ledsagardokument” som ska följa med transporten.</p>
                <p>6. När du har fått varorna så sänder du in deklarationsblanketten till Skatteverket och skatten dras då från det belopp vilket inbetalats tidigare som säkerhet.</p>
                <p>7. Dessa transaktioner kan du sedan följa upp via den e-tjänst som finns på skatteverkets hemsida. Klart!</p>
            </TextContent>
        </div> -->
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import ImgContent   from '@/components/contents/ImgContent.vue'

    export default {
        components: { TitleContent, TextContent, ImgContent }
    }
</script>

<style scoped>
    .content3 {

        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 2fr 1fr; 
        grid-template-rows: 75px auto auto; 
        gap: 0px 2vw; 
        grid-template-areas: 
            "title title"
            "text img"
            /* "text2 text2" */; 
    }

    .title { grid-area: title; }
    .text { grid-area: text; }
    .img { grid-area: img; }
    /* .text2 { grid-area: text2; margin: 0 5em; margin-top: 10px;} */


    @media screen and (max-width: 650px) {

        /* .text2 { margin: 0; } */
        .content3 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px auto auto auto; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "img"
                "text"
                /* "text2" */; 
            
        }
    }

</style>