<template>
    <img id="favimg" src="@/assets/favicon.png" alt="Loga">
</template>

<script>
    export default {

    }
</script>

<style>
    #favimg {
        height: 32px; 
        width: 32px; 
        justify-content: center; 
        align-content: center;
    }
</style>