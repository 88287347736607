<template>
    <div class="verify-age-container">
        <p class="verify-age-text">
            <slot></slot>
        </p>
        <button @click="oldEnough" class="verify-age-button default-shadow"> {{ yes }} </button>
        <button @click="notOldEnough" class="verify-age-button default-shadow"> {{ no }} </button>
    </div>
</template>

<script>
    import { ref } from 'vue'

    export default {
        /* props: ['yes', 'no'], */
        props: {
            yes: { type: String, default: "YES"},
            no:  { type: String, default: "no" }
        },
        setup(props, context) {

            const { yes, no } = props

            const oldEnough = () => {
                context.emit('oldEnough')
                document.cookie += "age=18; "
            }

            const notOldEnough = () => {
                context.emit('notOldEnough')
            }

            return { oldEnough, notOldEnough, yes, no }
        }
    }
</script>

<style>
    .verify-age-container {
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-direction: column;
    }

    .verify-age-text {
        color: white;
        font-size: 38px;
        text-shadow: 0px 0px 10px black;
    }

    .verify-age-button {
        margin: 6px calc(50% - 175px);
        padding: 15px 0;
        
        background-color: whitesmoke;
        border: 2px solid black;
        color: black;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
    }
</style>