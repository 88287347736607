<template>
    <div class="content2">
        <div class="title">
            <TitleContent>
                Mehr über <i>Sommaräng</i>
            </TitleContent>
        </div>
        <div class="text">
            <TextContent>
                <p>Der Wein Sommaräng wird aus der klassischen Rebsorte Riesling gekeltert. Sommaräng hat einen leichten, frischen und fruchtigen Geschmack mit einer schönen Zitrusnote und verbindet die rieslingtypische Säure angenehm mit einer feinen Frucht. Sommaräng eignet sich hervorragend zu Fisch und hellem Fleisch, du kannst Sommaräng aber auch einfach so genießen. </p>
                <p>Sommaräng soll gut gekühlt serviert werden, bei ca. 8-10 Grad Celcius.</p>
                <div style="font-size: 20px"> <p>Bitte beachte, dass wir nur Kartongs mit 6 oder 12 Flaschen versenden können.</p></div>
                <!-- <p>Der Preis für eine Flasche Sommaräng beträgt EUR 8,50 inkl. MwSt.</p> -->
            </TextContent>
        </div>
        <div class="product">
            <ProductCardGerman id="9ce95e1f-8e02-44fd-adee-f6369e08054f" />
        </div>
        <div class="text2">
            <TextContent>
                <p>Preis 6-er Kartong: EUR 50,00 inkl. MwSt und Versand <br>
                Preis 12-er Kartong: EUR 95,00 inkl. MwSt und Versand <br>
                Der Preis für eine Flasche Sommaräng beträgt EUR 8,50 inkl. MwSt.</p>
            </TextContent>
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import ImgContent   from '@/components/contents/ImgContent.vue'
    import ProductCardGerman  from '@/components/contents/ProductCardGerman.vue'

    export default {
        components: { TitleContent, TextContent, ImgContent, ProductCardGerman }
    }
</script>

<style scoped>
    .content2 {
            
        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 300px 1fr; 
        grid-template-rows: 75px 1fr; 
        gap: 0px 2vw; 
        grid-template-areas: 
            "title title"
            "product text"
            "text2 text2"; 
    }

    .title { grid-area: title; }
    .text { grid-area: text; }
    .text2 { grid-area: text2; }
    .product { grid-area: product; }


    /* @media screen and (max-width: 900px) {

        .content2 {
            display: grid; 
            grid-template-columns: 300px 1fr; 
            grid-template-rows: 75px 1fr; 
            /* gap: 0px 0px;  * /
            grid-template-areas: 
                "title title"
                "product text"; 
            
        }
    } */

    

    @media screen and (max-width: 900px) {

        .content2 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px 1fr auto; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "product"
                "text"
                "text2"; 
            
        }
    }
</style>