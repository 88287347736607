<template>
    <div class="content5">
        <div class="title">
            <TitleContent>
                Von der Rebe zum Wein
            </TitleContent>
        </div>

        <div class="img1">
            <ImgContent>
                <img src="@/assets/klippa-vin-oli.jpg" alt="Oliver Bechtel klipper vin">
            </ImgContent>
        </div>

        <div class="text text1">
            <TextContent>
                <p>Das Jahr im Weinberg beginnt mit dem Rebschnitt. Die meisten alten Reben werden abgeschnitten und nur eine Rebe wird behalten aus dem die neuen Triebe herauswachsen werden. </p>
            </TextContent>
        </div>
        <div class="img2">
            <ImgContent>
                <img src="@/assets/vinberg-vinter-vy.jpg" alt="Vinodling på vintern">
            </ImgContent>
        </div>
        <div class="text text2">
            <TextContent>
                <p>Bild vom Weinberg im Winter. </p> 
            </TextContent>
        </div>
        <div class="img3">
            <ImgContent>
                <img src="@/assets/vinodlingjuni-ny.jpeg" alt="Vinodling Juni">
            </ImgContent>
        </div>
        <div class="text text3">
            <TextContent>
                <p>Im Mai und Juni wachsen die Reben und müssen mehrmals mit Spalierdrähten "in Form gebracht" werden.</p>
            </TextContent>
        </div>
        <div class="img4">
            <ImgContent>
                <img src="@/assets/druvor-på-vinstock-vy.jpg" alt="vindruvor i rieslingfältet, druvor på vinstock + vy">
            </ImgContent>
        </div>
        <div class="text text4">
            <TextContent>
                <p>Im August beginnen die Trauben zu reifen. Dieses Bild zeigt die Weinberg aus dem später der Wein Sommaräng wird.</p>
            </TextContent>
        </div>
        <div class="img5">
            <ImgContent>
                <img src="@/assets/druvor-på-vinstocken.jpg" alt="druvor på vinstocken närbild">
            </ImgContent>
        </div>
        <div class="text text5">
            <TextContent>
                <p>Das Bild zeigt die Trauben aus dem später der Wein Sommaräng wird, die Trauben wachsen dicht was typisch für den Riesling ist. Riesling ist heutzutage auch die am häufigsten angebaute Rebsorte im Weinanbaugebiet Rheinhessen.</p>
            </TextContent>
        </div>
        <div class="img6">
            <ImgContent>
                <img src="@/assets/skördade-druvor.jpg" alt="druvor i hand">
            </ImgContent>
        </div>
        <div class="text text6">
            <TextContent>
                <p>Wenn die Trauben so aussehen, ist es Zeit zu ernten, was man Weinlese nennt. Bevor die Trauben gelesen wurden, wurde eine Selektion durchgeführt: Trauben, die noch nicht reif oder krank waren wurden per Hand entfernt damit sie die Qualität des zukünftigen Weines nicht negativ beeinträchtigen.</p>
            </TextContent>
        </div>
        <div class="img7">
            <ImgContent>
                <img src="@/assets/druv-skorde-maskin.jpg" alt="Vollernter">
            </ImgContent>
        </div>
        <div class="text text7">
            <TextContent>
                <p>Am 14. Oktober 2021 wurden die Trauben dann mit einem Traubenvollernter gelesen. </p>
            </TextContent>
        </div>
        <div class="img8">
            <ImgContent>
                <img src="@/assets/druvskördemaskin-vy.jpg" alt="Vy från vinskördemaskin">
            </ImgContent>
        </div>
        <div class="text text8">
            <TextContent>
                <p>Der Traubenvollernter schüttelt die Trauben vom Rebstock und fängt sie auf. Anschließend werden sie in einen Behälter auf der Maschine transportiert. </p>
            </TextContent>
        </div>
        <div class="img9">
            <ImgContent>
                <img src="@/assets/druvskördemaskin-tömmer.jpg" alt="tömning av druvorna">
            </ImgContent>
        </div>
        <div class="text text9">
            <TextContent>
                <p>Wenn der Behälter voll ist, wir er in einen großen Anhänger entleert und dann mit dem Traktor nach Hause zum Hof transportiert.</p>
            </TextContent>
        </div>
        <div class="imgA">
            <ImgContent>
                <img src="@/assets/vintankar.jpg" alt="vintankar">
            </ImgContent>
        </div>
        <div class="text textA">
            <TextContent>
                <p>Auf dem Hof werden die Trauben gekeltert und der Saft, den man Most nennt, wird in große Gärtanks gefüllt in denen der Most langsamt und mit relativ niedrigen Temperaturen vergärt. Danach kommt der Jungwein in Lagertanks und reift bis es Zeit ist in Flaschen abgefüllt zu werden. </p>
            </TextContent>
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import ImgContent   from '@/components/contents/ImgContent.vue'
    import ProductCard  from '@/components/contents/ProductCard.vue'

    export default {
        components: { TitleContent, TextContent, ImgContent, ProductCard }
    }
</script>

<style scoped>

        .text {
            text-align: center;
            margin-left: 5vw;
            margin-right: 5vw;
            margin-top: 1vh;
            margin-bottom: 3vh;
        }

    .content5 {
            
        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 75px auto auto auto auto auto; 
        gap: 0px 2vw; 
        grid-template-areas: 
                "title title"
                "img1  img2"
                "text1 text2"
                "img3  img4"
                "text3 text4"
                "img5  img6"
                "text5 text6"
                "img7  img8"
                "text7 text8"
                "img9  imgA"
                "text9 textA";
    }

    .title { grid-area: title; }
    .text1 { grid-area: text1; }
    .text2 { grid-area: text2; }
    .text3 { grid-area: text3; }
    .text4 { grid-area: text4; }
    .text5 { grid-area: text5; }
    .text6 { grid-area: text6; }
    .text7 { grid-area: text7; }
    .text8 { grid-area: text8; }
    .text9 { grid-area: text9; }
    .textA { grid-area: textA; }
    .img1  { grid-area: img1;  }
    .img2  { grid-area: img2;  }
    .img3  { grid-area: img3;  }
    .img4  { grid-area: img4;  }
    .img5  { grid-area: img5;  }
    .img6  { grid-area: img6;  }
    .img7  { grid-area: img7;  }
    .img8  { grid-area: img8;  }
    .img9  { grid-area: img9;  }
    .imgA  { grid-area: imgA;  }



    @media screen and (max-width: 750px) {

        /* .text {
            text-align: center;
            margin-left: 5vw;
            margin-right: 5vw;
            margin-top: 1vh;
            margin-bottom: 3vh;
        } */

        .content5 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px auto auto auto auto auto auto auto auto auto auto; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "img1 "
                "text1"
                "img2 "
                "text2"
                "img3 "
                "text3"
                "img4 "
                "text4"
                "img5 " 
                "text5"
                "img6 "
                "text6"
                "img7 "
                "text7"
                "img8 "
                "text8"
                "img9 "
                "text9"
                "imgA "
                "textA";
    
            
        }
    }
</style>