<template>
    <div class="content3">
        <div class="title">
            <TitleContent>
                Om mig
            </TitleContent>
        </div>
        <div class="text">
            <TextContent>
                <p>Oliver Bechtel, född 2002 i Bryssel, svensk mamma, tysk pappa, uppvuxen i Stockholm, nu i Tyskland för att plugga till vinbonde. Vin har odlats i familjen sedan många generationer och efter att en generation hoppade över så var vingårdens framtid något oviss. Farmor och farfar jobbade på, de senaste åren med enbart druvproduktionen och hoppades att något av barnbarnen skulle vilja fortsätta. Nu är jag här och tillsammans sköter vi om vingården som har ca. 60 000 vinstockar med en mångfald av olika druvsorter. Sakta men säkert kommer vi att börja bygga upp vinsortimentet igen och hoppas att om något år kunna erbjuda flera olika sorters vin.</p>
            </TextContent>
        </div>
        <div class="img">
            <ImgContent>
                <img src="@/assets/oliver.jpg" alt="">
            </ImgContent>
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import ImgContent   from '@/components/contents/ImgContent.vue'

    export default {
        components: { TitleContent, TextContent, ImgContent }
    }
</script>

<style scoped>
    .content3 {

        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 2fr 1fr; 
        grid-template-rows: 75px auto auto; 
        gap: 0px 2vw; 
        grid-template-areas: 
            "title title"
            "text img"
            "text2 text2"; 
    }

    .title { grid-area: title; }
    .text { grid-area: text; }
    .img { grid-area: img; }


    @media screen and (max-width: 900px) {

        .content3 {
            display: grid; 
            grid-template-columns: 1fr 300px; 
            grid-template-rows: 75px auto auto; 
            /* gap: 0px 0px; */ 
            grid-template-areas: 
                "title title"
                "text img"
                "text2 text2"; 
            
        }
    }

    @media screen and (max-width: 650px) {

        .content3 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px auto auto auto; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "img"
                "text"
                "text2"; 
            
        }
    }

</style>