<template>
    <div class="footer default-shadow">
        <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem minus, necessitatibus laudantium minima voluptate possimus dolor unde eos, perferendis impedit enim, autem ex. Corrupti quae sequi incidunt quo dolor maxime? -->
        <slot></slot>
    </div>
</template>

<script>

</script>

<style>
    .footer {
        background-color: #444;
        align-self: end; 
        min-height: 150px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        align-content: stretch;

        margin-block-start: 0;
        margin-block-end: 0;
        padding: 0;
        margin-block: 0;


        /*position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1rem;
        
        /*position: fixed;
        left: 0;
        bottom: 0;*/
        /*width: 100%;
        background-color: red;
        text-align: center;*/
    }

    .footer > p {
        padding: 1em 2em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-block: 0;

        font-size: 16px;
        color: white;
    
    }

    .footer > p > a {
        text-decoration: none;
        text-underline-position: below;
        font-size: 16px;
        color: white;
        
        transition-duration: 250ms;
    }
    
    .footer > p > a:hover {
        /* box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, .5); */
        text-shadow: 0px 1px 1px rgba(255, 255, 255, .5);
        font-size: 17px;
        color: whitesmoke;
        transition-duration: 400ms;
    }
</style>