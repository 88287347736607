<template>
    <div class="content4">
        <div class="title">
            <TitleContent>
                Vingården Bechtel <FavImg /> <!-- <img style="height: 32px; width: 32px; justify-content: center; align-content: center;" src="@/assets/favicon.png" alt=""> -->
            </TitleContent>
        </div>
        <div class="text">
            <TextContent>
                <p>Vingården Bechtel ligger i Rheinhessen, ett av Tysklands 13 vindistrikt, i närheten av floden Rhen. Vingården har varit i familjen Bechtels ägor sedan många generationer. Viner som gården genom tiderna har producerat är framförallt vita viner som Riesling, Müller Thurgau, Grauburgunder men också de röda vinena Spätburgunder och Dornfelder. Nu startar nästa generation sin resa till vinbonde och har under detta år producerat sitt första vin som nu säljs på flaska: <i>Sommaräng</i>, en fruktig Riesling.</p>
            </TextContent>
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import FavImg from '@/components/FavImg.vue'

    export default {
        components: { TitleContent, TextContent, FavImg }
    }
</script>

<style scoped>
    .content4 {
            
        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 1fr; 
        grid-template-rows: 75px 1fr; 
        gap: 0px 2vw; 
        grid-template-areas: 
            "title"
            "text"; 
    }

    .title { grid-area: title; }
    .text { grid-area: text; }


    /* @media screen and (max-width: 900px) {

        .content4 {
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px 1fr; 
            /* gap: 0px 0px;  * /
            grid-template-areas: 
                "title"
                "text"; 
            
        }
    } */

    @media screen and (max-width: 650px) {

        .content4 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px 1fr; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "text"; 
            
        }
    }
</style>