<template>
    <div class="content4">
        <div class="title">
            <TitleContent>
                Weingut Bechtel <FavImg /> <!-- <img style="height: 32px; width: 32px; justify-content: center; align-content: center;" src="@/assets/favicon.png" alt=""> -->
            </TitleContent>
        </div>
        <div class="text">
            <TextContent>
                <p>Das Weingut Bechtel liegt in dem kleinen Weinort Eimsheim, mitten in Rheinhessen, einem der 13 deutschen Weinbaugebiete. Das Weingut ist seit vielen Generationen in Familienbesitz. Die Weine, die das Weingut im Laufe der Jahre produziert hat, sind hauptsächlich Weißweine wie Riesling, Müller Thurgau, Silvaner und Grauburgunder, aber auch Rotweine wie Spätburgunder und Dornfelder. Jetzt beginnt die nächste Generation der Familie ihre Reise als Winzer und hat in diesem Jahr den ersten Wein produziert der jetzt in Flaschen verkauft wird: Sommaräng, ein frischer und fruchtiger trockener Riesling. </p>
            </TextContent>
        </div>
    </div>
</template>

<script>
    import TitleContent from '@/components/contents/TitleContent.vue'
    import TextContent  from '@/components/contents/TextContent.vue'
    import FavImg from '@/components/FavImg.vue'

    export default {
        components: { TitleContent, TextContent, FavImg }
    }
</script>

<style scoped>
    .content4 {
            
        margin: 0 2vw;

        display: grid; 
        grid-template-columns: 1fr; 
        grid-template-rows: 75px 1fr; 
        gap: 0px 2vw; 
        grid-template-areas: 
            "title"
            "text"; 
    }

    .title { grid-area: title; }
    .text { grid-area: text; }


    /* @media screen and (max-width: 900px) {

        .content4 {
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px 1fr; 
            /* gap: 0px 0px;  * /
            grid-template-areas: 
                "title"
                "text"; 
            
        }
    } */

    @media screen and (max-width: 650px) {

        .content4 { 
            /*text-align: center;*/
 
            display: grid; 
            grid-template-columns: 1fr; 
            grid-template-rows: 75px 1fr; 
            /* gap: 0px 0px;  */
            grid-template-areas: 
                "title"
                "text"; 
            
        }
    }
</style>