<template>  
    <div class="nav">
        <!-- <div class="icon icon1"><iconBarMenu id="padding" /></div> -->
        <div class="spacer"></div>
        <div class="title">{{ title }}</div>
        <!-- <div class="icon icon3" @click=""></div> -->
        <router-link class="icon icon3" to="/de-DE" @click="changeTitle('de')"> <germanFlag class="flagpadding" /> </router-link>
        <router-link class="icon icon4" to="/"      @click="changeTitle('sv')"> <swedenFlag class="flagpadding" />  </router-link>
        <!-- <div class="icon icon2" @click="showModal"> <iconShopCart id="padding"/> </div> -->
    </div>
</template>

<script>
    import iconBarMenu from '../assets/menu-outline.vue'
    import iconShopCart from '../assets/cart-outline.vue'
    /* import orderModal from '@/components/modals/orderModal.vue' */
    import germanFlag from '@/assets/german-flag.vue'
    import swedenFlag from '@/assets/sweden-flag.vue'
    import { ref } from 'vue'

    export default {
        components: { iconBarMenu, iconShopCart, /* orderModal, */ germanFlag, swedenFlag },
        setup(props, context) {

            /* let modalStatus = ref(false) */
            let title = ref('Vingården Bechtel')

            const changeTitle = (lang)  => {

                switch(lang) {
                    
                    case "/de-DE":
                    case "de":
                        title.value = "Weingut Bechtel"
                        break

                    case "/":
                    case "sv":
                    default:
                        title.value = "Vingården Bechtel"
                        break
                }
            }

            /* const closeModal = () => {
                modalStatus.value = false
            } */

            /* const showModal = () => {
                console.log("SHOWMODAL")
                console.log(modalStatus.value)
                modalStatus.value = true
            } */

            return { title, /* modalStatus, */ /* closeModal, */ /* showModal, */ /* changeLang, */ changeTitle }
        },
        mounted() {
            /* this.changeLang(this.$route.path)
            this.changeLang(this.$route.query) */
            /* this.changeLang(window.location.pathname) */
            this.changeTitle(window.location.pathname)
        }
    }
</script>

<style>


    .flagpadding {
        /* padding: auto auto;
        margin: auto auto;
        padding: 25% 10%; */
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 25%;
        padding-bottom: 25%;
    }

    .nav {
        display: grid;
        /* grid: 1fr / 4em 1fr 4em; */
        /* grid-template-columns: 4em 8em 1fr 4em 4em 4em; */
        grid-template-columns: 8em 1fr 4em 4em;
        grid-template-rows: 1fr;
        /* grid-template-areas: "icon1 spacer title icon3 icon4 icon2"; */
        grid-template-areas: "spacer title icon3 icon4";
        background-color: #333;
        color: white;

        /*display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-color: lightyellow;
        height: 4em; */

        box-shadow: inset 0px 0px 1px 0px black;
    }

    .icon1 { grid-area: icon1; }
    .title { grid-area: title; }
    .icon2 { grid-area: icon2; }
    .icon3 { grid-area: icon3; }
    .icon4 { grid-area: icon4; }
    .spacer {grid-area: spacer; width: 128px; height: 64px; }
    
    /*.nav > div {
        border: 2px dashed red;
    }*/

    .title {
        text-align: center;
        font-size: 40px;
        align-self: center;
        /*height: 100%;/** */
        /*align-self: flex-end;
        padding-bottom: .25em;/**/
        /*flex: 1;*/
    }

    .icon {
        /* display: hidden; */
        /* display: none; */

        /*padding: .5em; /**/
        height: 64px;
        width: 64px;
        /*box-shadow: 0px 0px 5px 1px black;
        box-shadow: inset 0px 0px 3px 0px black; /** */
        /*box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, .7), inset 0px 0px 3px 0px rgba(0, 0, 0, .7); /** */
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, .7), inset 0px 0px 1px 0px rgba(0, 0, 0, .7);
        transition: 125ms;

        /*width: 2em;
        margin: 0em;
        padding: 1em;*/
    }

    .icon:hover {
        background-color: #222;
        transition: 125ms;
    }

    #padding {
        padding: .5em;
    }

    

    @media screen and (max-width: 650px) {

        /* .nav {
            grid-template-rows: 1fr;
            grid-template-columns: 3em 1fr 3em;
        } */

        /* .icon {
            margin: auto 0;
            height: calc(6vw + 25px);
            width: calc(6vw + 25px);;
        } */


        .icon2 {
            margin-left: auto;
        }
        
        .title {
            /* font-size: calc(5vw + 8px); */
            font-size: calc(6vw + 0px);
        }
    }

</style>